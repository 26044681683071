<script lang="ts" setup>
const store = useModalStore();
</script>

<template>
  <div v-if="store.isPictureModal" class="modal-container" @click="store.toggleIsPictureModal">
    <NuxtImg :src="$config.public.apiUrl + '/api/moysklad/image/' + store.getPictureSrc"></NuxtImg>
  </div>
</template>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  @apply z-30;
}
</style>
